body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #1e1e1e; /* Dark background */
  color: #fff; /* Light text */
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
